import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import Button from 'ihp-components/components/v2/Button';

import { ProfileHeader } from 'components/Profile';
import { ProfileCard } from 'components/Profile/ProfileCard';
import { getRewardsInfo } from 'components/Payment/Rewards/util';
import { ReactComponent as ArrowRight } from 'images/shared/chevron-right.svg';
import { ReactComponent as ProfileSetting } from 'images/profile/edit-profile.svg';
import { ReactComponent as MedicalNotes } from 'images/profile/notes-medical.svg';
import { ReactComponent as ChevronRight } from 'images/shared/chevron-right-2.svg';
import { ReactComponent as Sign } from 'images/profile/sign-2.svg';
import { ReactComponent as StudyWithdrawal } from 'images/profile/study-withdrawal.svg';
import { ReactComponent as MyProviders } from 'images/profile/my-providers.svg';
import { ReactComponent as AccountHolderProfileIcon } from 'images/profile/accountholder-profile.svg';
import { ReactComponent as ParticipantProfileIcon } from 'images/profile/participant-profile.svg';
import { ResponsiveSideBySideLayout } from 'configUI/components/Shared';
import { StyledBaseLayout, CardList } from './styles';
import { useLogout } from 'utils/useLogout';
import { findStudyAttribute } from 'utils/study';
import { withStudyArmRolePermissions } from 'hocs/withStudyArmRolePermissions';
import { getBoolPropertyValue } from 'utils/configParseUtils';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { useIsActiveProfileContactRole } from 'features/profile/hooks/useIsActiveProfileContactRole';
import { UpdateProfileWarningModal } from 'features/activities/updateProfile/components/UpdateProfileWarningModal';
import { useState } from 'react';

const CURRENT_ROUTE = '/profile';

const Profile = () => {
  document.title = 'Single Ventricle SOURCE My Profile';
  const navigate = useNavigate();
  const match = useMatch(CURRENT_ROUTE);
  const logout = useLogout();
  const checkNavigationDisplayProperty = {};

  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const { studyConfiguration, participantProfileConfig } = useSelector(
    (state) => state.configuration
  );

  // API Calls
  const { data } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const rewardsData = getRewardsInfo(data?.data);
  const role = activeAccountProfile?.attributes?.profile?.study_arm_role_name;

  const profileNavigation = findStudyAttribute(
    studyConfiguration || [],
    'profile_navigation'
  );

  const { isActiveProfileContactRole } = useIsActiveProfileContactRole();

  const isNavigationOptionEnabled = (config, targetKey) => {
    const found = config?.find((c) => c.key === targetKey && c.display);
    return found !== undefined;
  };

  if (participantProfileConfig?.accountHolderProfile) {
    checkNavigationDisplayProperty.accountHolderProfile = getBoolPropertyValue(
      participantProfileConfig?.accountHolderProfile?.meta?.display
    );
  }

  if (participantProfileConfig?.participantProfile) {
    checkNavigationDisplayProperty.participantProfile = getBoolPropertyValue(
      participantProfileConfig?.participantProfile?.meta?.display
    );
  }
  if (participantProfileConfig?.myStudy) {
    checkNavigationDisplayProperty.myStudy = getBoolPropertyValue(
      participantProfileConfig?.myStudy?.meta?.display
    );
  }
  if (participantProfileConfig?.myConsents) {
    checkNavigationDisplayProperty.myConsents = getBoolPropertyValue(
      participantProfileConfig?.myConsents?.meta?.display
    );
  }
  if (participantProfileConfig?.myProviders) {
    checkNavigationDisplayProperty.myProviders = getBoolPropertyValue(
      participantProfileConfig?.myProviders?.meta?.display
    );
  }
  if (participantProfileConfig?.changeOfStatus) {
    checkNavigationDisplayProperty.changeOfStatus = getBoolPropertyValue(
      participantProfileConfig?.changeOfStatus?.meta?.display
    );
  }

  const getTitle = (config, key) => {
    const found = config.filter((n) => n.key === key);
    if (found.length === 1) {
      return found[0]?.label['en-us'];
    }
    return null;
  };

  const menuItems = [
    {
      title: getTitle(profileNavigation, 'participantProfile'),
      description: 'Manage and edit participant',
      linkedRoute: '/profile/participant-profile',
      onClick: () => navigate('/profile/participant-profile'),
      renderIcon: () => {
        return <ParticipantProfileIcon width='20px' height='20px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'participantProfile') &&
        checkNavigationDisplayProperty['participantProfile'],
    },
    {
      title: getTitle(profileNavigation, 'edit_profile'),
      description: 'Update your personal information',
      linkedRoute: '/profile/my-profile',
      onClick: () => navigate('/profile/my-profile'),
      renderIcon: () => {
        return <ProfileSetting width='20px' height='20px' />;
      },
      display: isNavigationOptionEnabled(profileNavigation, 'edit_profile'),
    },
    {
      title: getTitle(profileNavigation, 'my_study'),
      description: 'Review your study information',
      linkedRoute: '/profile/my-study',
      onClick: () => navigate('/profile/my-study'),
      renderIcon: () => {
        return <MedicalNotes height='24px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'my_study') &&
        checkNavigationDisplayProperty['myStudy'],
    },
    {
      title: getTitle(profileNavigation, 'my_consents'),
      description: 'View and download your consents',
      linkedRoute: '/profile/consents',
      onClick: () => navigate('/profile/consents'),
      renderIcon: () => {
        return <Sign height='24px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'my_consents') &&
        checkNavigationDisplayProperty['myConsents'],
    },
    {
      title: getTitle(profileNavigation, 'my_providers'),
      description: 'Review your shared information',
      linkedRoute: '/profile/my-providers',
      onClick: () => navigate('/profile/my-providers'),
      renderIcon: () => {
        return <MyProviders height='64px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'my_providers') &&
        checkNavigationDisplayProperty['myProviders'],
    },
    {
      title: getTitle(profileNavigation, 'study_withdrawal'),
      description: 'Manage your study participation',
      linkedRoute: '/profile/study-withdrawal',
      onClick: () => navigate('/profile/study-withdrawal'),
      renderIcon: () => {
        return <StudyWithdrawal height='64px' />;
      },
      display: isNavigationOptionEnabled(profileNavigation, 'study_withdrawal'),
    },
    {
      title: getTitle(profileNavigation, 'change_of_status'),
      description: 'Manage your study status',
      linkedRoute: '/profile/change-status',
      onClick: () => navigate('/profile/change-status'),
      renderIcon: () => {
        return <StudyWithdrawal height='64px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'change_of_status') &&
        checkNavigationDisplayProperty['changeOfStatus'],
    },
    {
      title: 'Family',
      description: 'View your family linkages',
      linkedRoute: '/profile/family',
      onClick: () => navigate('/profile/family'),
      renderIcon: () => {
        return <ParticipantProfileIcon height='64px' />;
      },
      display: !isActiveProfileContactRole,
    },
    // SING-1120: Reorder Profile tabs
    {
      title: getTitle(profileNavigation, 'accountHolderProfile'),
      description: 'Update your personal information',
      linkedRoute: '/profile/accountholder-profile',
      onClick: () => navigate('/profile/accountholder-profile'),
      renderIcon: () => {
        return <AccountHolderProfileIcon width='20px' height='20px' />;
      },
      display:
        isNavigationOptionEnabled(profileNavigation, 'accountHolderProfile') &&
        checkNavigationDisplayProperty['accountHolderProfile'],
    }
  ];

  if(isActiveProfileContactRole){
    const lastElement = menuItems.pop();
    menuItems.unshift(lastElement);
  }

  const { state } = useLocation();

  const [showUpdateProfileWarningModal, setShowUpdateProfileWarningModal] =
    useState(Boolean(state?.redirectedFromUpdateProfileActivity));

  return (
    <ResponsiveSideBySideLayout isParentActive={Boolean(match)}>
      <StyledBaseLayout
        content={
          <Stack gap='30px'>
            <ProfileHeader heartBeats={rewardsData?.balance} />
            <CardList>
              {menuItems.map((item) => {
                return (
                  item.display && (
                    <ProfileCard
                      icon={item.renderIcon()}
                      title={item.title}
                      description={item.description}
                      linkedRoute={item.linkedRoute}
                      onClick={item.onClick}
                    >
                      <ChevronRight />
                    </ProfileCard>
                  )
                );
              })}
            </CardList>
          </Stack>
        }
        footer={
          <Button variant='link' sx={{ gap: '10px' }} onClick={logout}>
            Sign out <ArrowRight />
          </Button>
        }
      />

      <UpdateProfileWarningModal
        onClose={() => setShowUpdateProfileWarningModal(false)}
        open={showUpdateProfileWarningModal}
      />
    </ResponsiveSideBySideLayout>
  );
};

export default withPerson(
  withSelectedProfile(withStudyArmRolePermissions(Profile))
);
