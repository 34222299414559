// SING-631
// import { useEffect, useState } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllCountriesQuery } from 'ihp-bloom-redux/features/countries/countriesApiSlice';
import {
  useDeletePersonAttributesMutation,
  useEditPersonAttributesMutation,
} from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import {
  useGetAllPersonStudyArmAttributesQuery,
  useDeletePersonStudyArmAttributeMutation,
} from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

import Button from 'ihp-components/components/v2/Button';

import { useGeneralInfo } from './useGeneralInfo';
import { findFirstErrorComponent } from 'utils/formValidation';

import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';

import {
  getParticipantProfileTabInfo,
  FIELD_TYPE_RENDERERS_MAP,
  first_name,
  last_name,
  middle_name,
  no_middle_name,
  multiselect,
  country_of_birth,
  sex_at_birth,
  ethnicity,
  gender,
  educational_level,
  marital_status,
  birth_no_middle_name,
  birth_first_name,
  birth_middle_name,
  birth_last_name,
  date_of_death,
  date_of_birth,
  year_of_birth,
  different_name_at_birth,
  participant_deceased,
} from 'utils/configParseUtils';

import { withPersonAttributes } from 'hocs/withPersonAttributes';

import { radioButtonOptions, useDynamicRefs } from './helper';

import {
  StyledBox,
  Title,
  ButtonContainer,
  HorizontalDivider,
  getStyles,
} from './styles';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';
import { formatDate, formatDateToDB, parseDate } from 'utils/date';

const mapValue = {
  true: true,
  false: false,
};

const Info = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const initialValueAttributes = {};

  const { participantProfileConfig, studyEnumerations } = useSelector(
    (state) => state.configuration
  );
  const { activeAccountProfile } = useSelector((state) => state.user);

  // STATUSES
  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;
  const subjectPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;
  const countryName =
    activeAccountProfile?.attributes?.person?.attributes?.country;
  const studyArmRole =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;

  const { countriesList, isLoading: isCountriesLoading } =
    useGetAllCountriesQuery(undefined, {
      selectFromResult: ({ data, ...rest }) => ({
        countriesList: data?.data?.map((country) => ({
          label: country?.attributes?.country_name,
          value: country?.attributes?.country_code2,
        })),
        ...rest,
      }),
    });

  const {
    data: personStudyArmAttributes,
    isFetching,
    refetch,
  } = useGetAllPersonStudyArmAttributesQuery({
    personStudyArmId: subjectPrimaryPersonStudyArmId,
    'page[limit]': 10000,
  });

  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();
  const [deletePersonAttributes, { isLoading: isDeletingPersonAttributes }] =
    useDeletePersonAttributesMutation();
  const [
    deletePersonStudyArmAttribute,
    { isLoading: isDeletingPersonStudyArmAttributes },
  ] = useDeletePersonStudyArmAttributeMutation();
  const {
    createPersonStudyArmAttributeRecord,
    isLoading: isLoadingCreatePersonStudyArmAttribute,
  } = useGeneralInfo();

  const { fieldsMap, isTabReadOnly } = getParticipantProfileTabInfo(
    participantProfileConfig,
    'General Info',
    countryName
  );

  const availableFields = fieldsMap.reduce((acc, obj) => {
    const nameFields = obj.fields
      .filter((field) => field?.name)
      .map((field) => {
        if (field.show) {
          return field.name;
        }
      });
    return acc.concat(nameFields);
  }, []);

  Object.keys(props.personAttributes || {})?.forEach((key) => {
    if (availableFields?.includes(key)) {
      initialValueAttributes[key] =
        mapValue[props.personAttributes[key]] ?? props.personAttributes[key];
    }
    if (key === date_of_birth) {
      initialValueAttributes[key] = parseDate(props.personAttributes[key]);
    }
  });
  /**
   * make sure for all available(show=true) fields we set initial value so all input components render as controlled components
   */
  availableFields?.forEach((field) => {
    if (initialValueAttributes[field] === undefined) {
      initialValueAttributes[field] =
        field === date_of_death || field === date_of_birth ? null : '';
    }
  });

  const refMap = useDynamicRefs(availableFields);

  const {
    control,
    watch,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
  } = useForm({
    defaultValues: { ...initialValueAttributes },
  });

  const raceInitialOptionsList = (studyEnumerations?.race_ethnicity || [])
    ?.filter((item) => item?.value !== 'more_than_one_race')
    ?.map((item) => ({
      ...item,
      checked: false,
    }));
  const [raceOptionsList, setRaceOptionsList] = useState(
    raceInitialOptionsList
  );

  const isNoMiddleNameChecked = watch(no_middle_name);
  const isNoBirthMiddleNameChecked = watch(birth_no_middle_name);
  const selectedOptionBirth = watch(different_name_at_birth);
  const selectedOptionDeceased = watch(participant_deceased);
  const [initialValue, setInitialValue] = useState('');

  const theme = useTheme();
  const styles = getStyles(
    theme,
    isNoMiddleNameChecked,
    isNoBirthMiddleNameChecked,
    isMobile
  );

  const updateFormValues = (data) => {
    if (data) {
      const attributesMap = {};
      data?.data?.forEach((item) => {
        const attr = item?.attributes;
        attributesMap[attr?.attribute] = attr.value;
      });

      const participantDeceasedValue = attributesMap[participant_deceased];
      const dateOfDeathValue = attributesMap[date_of_death];
      if (participantDeceasedValue !== undefined) {
        setInitialValue(participantDeceasedValue);
        setValue(participant_deceased, participantDeceasedValue);
      }
      if (dateOfDeathValue !== undefined) {
        setValue(date_of_death, formatDate(dateOfDeathValue));
      }
    }
  };

  useEffect(() => {
    updateFormValues(personStudyArmAttributes);
  }, [personStudyArmAttributes, setValue]);

  const isLoading =
    isEditingPersonAttributes ||
    isCountriesLoading ||
    isFetching ||
    isLoadingCreatePersonStudyArmAttribute ||
    props?.isFetchingPersonAttributes ||
    isDeletingPersonAttributes ||
    isDeletingPersonStudyArmAttributes;

  const checkRaceOptions = () => {
    if (
      Object.keys(initialValueAttributes).length > 0 &&
      initialValueAttributes?.race_ethnicity
    ) {
      const selectedRace = initialValueAttributes?.race_ethnicity
        ?.split(',')
        .map((value) => value.trim());
      const updatedList = raceOptionsList.map((option) => ({
        ...option,
        checked: selectedRace.includes(option.value),
      }));
      setRaceOptionsList(updatedList);
    } else {
      setRaceOptionsList(raceInitialOptionsList);
    }
  };

  useEffect(() => findFirstErrorComponent(errors, refMap));

  useEffect(() => {
    checkRaceOptions();
  }, []);

  useEffect(() => {
    refetch?.();
  }, []);

  useEffect(() => {
    if (
      (isNoMiddleNameChecked !== '' || isNoMiddleNameChecked !== undefined) &&
      isNoMiddleNameChecked
    ) {
      setValue(middle_name, '');
    }
  }, [isNoMiddleNameChecked]);

  useEffect(() => {
    if (
      (isNoBirthMiddleNameChecked !== '' ||
        isNoBirthMiddleNameChecked !== undefined) &&
      isNoBirthMiddleNameChecked
    ) {
      setValue(birth_middle_name, '');
    }
  }, [isNoBirthMiddleNameChecked]);

  useEffect(() => {
    if (
      (selectedOptionBirth !== '' || selectedOptionBirth !== undefined) &&
      `${selectedOptionBirth}` === 'false'
    ) {
      setValue(birth_first_name, '');
      setValue(birth_middle_name, '');
      setValue(birth_last_name, '');
      setValue(birth_no_middle_name, '');
    }
  }, [selectedOptionBirth]);

  useEffect(() => {
    if (
      (selectedOptionDeceased !== '' || selectedOptionDeceased !== undefined) &&
      `${selectedOptionDeceased}` === 'false'
    ) {
      setValue(date_of_death, '');
    }
  }, [selectedOptionDeceased]);

  const handleCancel = () => {
    checkRaceOptions();
    reset(initialValueAttributes);
    updateFormValues(personStudyArmAttributes);
  };

  const handleOnChangeRace = (value, name) => {
    const updatedOptions = [...raceOptionsList];
    const itemIndex = updatedOptions.findIndex((item) => item.value === value);
    if (itemIndex !== -1) {
      updatedOptions[itemIndex].checked = !updatedOptions[itemIndex].checked;
    }
    //SING-193
    if (itemIndex === 8) {
      updatedOptions.forEach((entry,i)=>{
        if(i!==8){
          entry.checked=false;
        }
      });
    }
    //SING-193
    if(updatedOptions[8].checked && itemIndex !== 8){
      return;
    }
    const selectedRace = updatedOptions
      .filter((race) => race.checked)
      .map((race) => race.value);
    setValue('race_ethnicity', selectedRace?.join(','));

    clearErrors(name);
  };

  const checkDateOfDeathValidation = (dateOfBirth, dateOfDeath) => {
    const birthDate = new Date(dateOfBirth);
    const deathDate = new Date(dateOfDeath);
    let flag = true;
    if (dateOfBirth && dateOfDeath && deathDate < birthDate) {
      flag = false;
      setError(date_of_death, {
        type: 'custom',
        message: 'Date of death has to be after date of birth.',
      });
    } else {
      flag = true;
      clearErrors(date_of_death);
    }
    return flag;
  };

  const onSubmit = async (data) => {
    let forRemoval = [];
    let payload = [];
    let excludedAttributes = [date_of_death, participant_deceased];
    let excludedPayload = [];

    if (
      data.date_of_death &&
      !checkDateOfDeathValidation(data.date_of_birth, data.date_of_death)
    ) {
      return;
    }

    for (let key in data) {
      if (data[key] === undefined || data[key] === '' || data[key] === null) {
        forRemoval.push(key);
      }
    }

    /**
     * Filter out attributes which does not exists in BE side and only have default initial value
     */
    const filteredPersonStudyAramAttributes = forRemoval?.filter((key) => {
      return personStudyArmAttributes?.data?.some((item) =>
        Object.values(item.attributes).includes(key)
      );
    });

    if (filteredPersonStudyAramAttributes?.length > 0) {
      await deletePersonStudyArmAttribute({
        personStudyArmId: subjectPrimaryPersonStudyArmId,
        payload: { attributes: filteredPersonStudyAramAttributes },
      });
    }

    const filteredPersonAttributes = forRemoval?.filter((key) => {
      if (props.personAttributes[key] === undefined) {
        return false;
      }
      return true;
    });

    if (filteredPersonAttributes?.length > 0) {
      await deletePersonAttributes({
        personId: subjectPersonId,
        payload: { attributes: filteredPersonAttributes },
      });
    }

    if (
      data.date_of_birth !== '' &&
      data.date_of_birth !== null &&
      data.date_of_birth !== undefined
    ) {
      data.date_of_birth = formatDateToDB(data.date_of_birth);
    }

    if (
      data.date_of_death !== '' &&
      data.date_of_death !== null &&
      data.date_of_death !== undefined
    ) {
      data.date_of_death = formatDateToDB(data.date_of_death);
    }

    for (let key in data) {
      if (data[key] === undefined || data[key] === '' || data[key] === null) {
        delete data[key];
      } else {
        if (excludedAttributes.includes(key)) {
          const excludedObj = {
            attribute: key,
            value: typeof data[key] === 'boolean' ? `${data[key]}` : data[key],
          };
          excludedPayload.push(excludedObj);
        } else {
          const obj = {
            data: {
              type: 'person-attributes',
              attributes: {
                attribute: key,
                value:
                  typeof data[key] === 'boolean' ? `${data[key]}` : data[key],
              },
            },
          };
          payload.push(obj);
        }
      }
    }

    try {
      if (studyArmRole !== STUDY_ARM_ROLE_NAME.PARTICIPANT) {
        if (excludedPayload.length > 0) {
          const personStudyArmAttributeResponse =
            await createPersonStudyArmAttributeRecord(
              subjectPrimaryPersonStudyArmId,
              excludedPayload
            );

          if (personStudyArmAttributeResponse.error) {
            console.error(
              'Error in person study arm attribute:',
              personStudyArmAttributeResponse.error
            );
            return;
          }
        }
      }

      const personAttributesResponse = await editPersonAttributes({
        personId: subjectPersonId,
        payload: payload,
      });

      if (personAttributesResponse.error) {
        console.error(
          'Error in person attributes:',
          personAttributesResponse.error
        );
        return;
      }

      refetch?.();
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const sortedEductionalLevel = [...(studyEnumerations?.educational_level ?? [])].sort(
    (a, b) => {
      const sortSequence1 = a.value.match(/^\d+/)[0] ?? 0;
      const sortSequence2 = b.value.match(/^\d+/)[0] ?? 0;
      return Number(sortSequence1) - Number(sortSequence2);
    }
  );

  return (
    <form style={styles.formStyle}>
      {fieldsMap?.map((obj, index) => (
        <>
          <StyledBox>
            <Title>{obj?.name}</Title>
            <Grid container rowSpacing={3} columnSpacing={3}>
              {obj?.fields?.map((mappedField) => {
                let {
                  type,
                  name,
                  label,
                  placeholder,
                  Component: Field,
                  disabled,
                  colSpan,
                  validation,
                  required,
                  show,
                  items,
                  ...rest
                } = mappedField;

                if (!required) {
                  label = `${label} (optional)`;
                }

                let customProps = {};

                const customRenderer =
                  FIELD_TYPE_RENDERERS_MAP[mappedField.name];
                const checkBoxStyle =
                  name === birth_no_middle_name
                    ? styles.checkboxNoBirthMiddleName
                    : name === no_middle_name
                    ? styles.checkboxNoMiddleName
                    : null;
                if (name === country_of_birth) {
                  customProps.optionsList = countriesList;
                }

                if (name === sex_at_birth) {
                  customProps.optionsList = studyEnumerations?.sex_at_birth;
                }
                if (name === ethnicity) {
                  customProps.optionsList = studyEnumerations?.ethnicity;
                }
                if (name === 'race_ethnicity') {
                  customProps.optionsList = studyEnumerations?.race_ethnicity;
                }
                if (name === gender) {
                  customProps.optionsList = studyEnumerations?.gender;
                }
                if (name === educational_level) {
                  customProps.optionsList = sortedEductionalLevel;
                }
                if (name === marital_status) {
                  customProps.optionsList = studyEnumerations?.marital_status;
                }
                if (name === no_middle_name) {
                  customProps.value = isNoMiddleNameChecked;
                  customProps.checked = isNoMiddleNameChecked;
                }

                if (name === birth_no_middle_name) {
                  customProps.value = isNoBirthMiddleNameChecked;
                  customProps.checked = isNoBirthMiddleNameChecked;
                }

                if (name === different_name_at_birth) {
                  customProps.radioButtonProps = radioButtonOptions;
                }
                if (name === participant_deceased) {
                  customProps.radioButtonProps = radioButtonOptions;
                }
                if (name === year_of_birth) {
                  customProps.showOnlyYear = true;
                }
                if (
                  name === different_name_at_birth &&
                  selectedOptionBirth !== ''
                ) {
                  validation = {
                    required: false,
                  };
                }

                if (
                  name === participant_deceased &&
                  selectedOptionDeceased !== ''
                ) {
                  validation = {
                    required: false,
                  };
                  if (studyArmRole === STUDY_ARM_ROLE_NAME.LAR) {
                    if (
                      `${selectedOptionDeceased}` === 'true' &&
                      initialValue === 'true'
                    ) {
                      disabled = true;
                    }
                    if (
                      initialValue !== 'true' &&
                      `${selectedOptionDeceased}` === 'true'
                    ) {
                      navigate('/profile/change-status');
                    }
                  }
                }
                if (name === middle_name && !isNoMiddleNameChecked) {
                  //   validation = {
                  //     required: false,
                  //   };
                }
                if (name === birth_middle_name && !isNoBirthMiddleNameChecked) {
                  //   validation = {
                  //     required: true,
                  //   };
                }
                if (
                  !show ||
                  (isNoMiddleNameChecked && name === middle_name) ||
                  (isNoBirthMiddleNameChecked && name === birth_middle_name) ||
                  ((`${selectedOptionBirth}` === 'false' ||
                    selectedOptionBirth === '') &&
                    (name === birth_first_name ||
                      name === birth_middle_name ||
                      name === birth_last_name ||
                      name === birth_no_middle_name)) ||
                  ((`${selectedOptionDeceased}` === 'false' ||
                    selectedOptionDeceased === '') &&
                    name === date_of_death)
                ) {
                  return null;
                }

                // SING-615
                if (
                  name === 'marital_status' &&
                  [
                    STUDY_ARM_ROLE_NAME.LARSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.LARSVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.LARNonSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.LARNonSVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.NonSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.ParentSVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.ParentNonSVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.ParentSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.ParentNonSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.SVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.NonSVChild7ToMajority,
                    STUDY_ARM_ROLE_NAME.ParentNonSVChildUnder7,
                    STUDY_ARM_ROLE_NAME.SVChildUnder7,
                  ].includes(studyArmRole)
                ) {
                  // SING-670
                  return null;
                }
                // End of SING-615

                return (
                  <Grid
                    item
                    xs={
                      isMobile
                        ? 12
                        : (name === first_name || name === last_name) &&
                          isNoMiddleNameChecked
                        ? 6
                        : (name === birth_first_name ||
                            name === birth_last_name) &&
                          isNoBirthMiddleNameChecked
                        ? 6
                        : name === date_of_birth
                        ? 6
                        : colSpan ?? 12
                    }
                    key={name}
                  >
                    <Controller
                      name={name}
                      control={control}
                      rules={validation}
                      render={({ field }) =>
                        customRenderer ? (
                          customRenderer({
                            label,
                            placeholder,
                            name,
                            inputRef: refMap[name],
                            error: errors?.[name],
                            disabled: disabled,
                            errorLabel:
                              errors?.[name]?.message ||
                              'This field is required',
                            ...rest,
                            ...field,
                            ...customProps,
                          })
                        ) : type === multiselect ? (
                          <Field
                            title={label}
                            options={raceOptionsList}
                            styles={styles}
                            error={errors?.[name]}
                            errorLabel={
                              errors?.[name]?.message ||
                              'This field is required'
                            }
                            inputRef={refMap[name]}
                            disabled={disabled}
                            handleOnChange={(value) =>
                              handleOnChangeRace(value, name)
                            }
                            {...rest}
                            {...field}
                          />
                        ) : (
                          <Field
                            name={name}
                            error={errors?.[name]}
                            errorLabel={
                              errors?.[name]?.message ||
                              'This field is required'
                            }
                            fullWidth={true}
                            label={label}
                            placeholder={placeholder}
                            containerStyle={checkBoxStyle}
                            disabled={disabled}
                            inputRef={refMap[name]}
                            {...rest}
                            {...field}
                            {...customProps}
                          />
                        )
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </StyledBox>
          {index !== fieldsMap?.length - 1 && <HorizontalDivider />}
        </>
      ))}

      {!isTabReadOnly && (
        <ButtonContainer>
          <Button
            sx={{ fontSize: '16px' }}
            disabled={isLoading}
            onClick={() => handleCancel()}
            variant='link'
          >
            Cancel
          </Button>

          <Button
            startIcon={<CheckIcon width={14} height={14} />}
            sx={styles.buttonStyle}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </ButtonContainer>
      )}
    </form>
  );
};
export default withPersonAttributes(Info);
