import { useCallback, useEffect, useRef } from 'react';
import { Form } from '@formio/react';

import i18n from './i18n';
import './def.css';
import './styles.css';
import './select.css';
import './select-multiple.css';
import { taskTypeLabelMapping } from 'pages/Activities/utils';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { DiagnosisKeyMap, formIOkeyMap, getFormattedDate } from './util';
import { useGetAllPersonStudyArmAttributesQuery } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { Loader } from 'components/Loader';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';
import { useGetPersonStudyArmSubRoleQuery } from 'ihp-bloom-redux/features/personStudyArmSubRole/personStudyArmSubRoleApiSlice';
import { getIParticipantSubroleSet } from '../../../Profile/ParticipantProfile/StudyContributors/relationships/helper';
import { isMobile as isMobileDevice } from 'utils/isMobile';
import { DUPLICATE_CHILD_EMAIL } from '../constants';

const setupExtraActions = ({ onCancel }) => {
  const cancelBtn = document.getElementById('btn-wizard-nav-cancel');
  if (onCancel && cancelBtn) {
    cancelBtn.addEventListener('click', onCancel);
  }

  const backBtn = document.getElementById('wizard-back-btn');
  if (onCancel && backBtn) {
    backBtn.addEventListener('click', onCancel);
  }

  return {
    removeListeners: () => {
      if (cancelBtn) cancelBtn.removeEventListener('click', onCancel);
      if (backBtn) backBtn.removeEventListener('click', onCancel);
    },
  };
};

const FormIO = ({
  form,
  isMobile,
  attributes: {
    title,
    description,
    type,
    estimatedTime,
    howTo,
    compensationValue,
    timeLimit,
  },
  onSubmit,
  onCancel,
  onSave,
  onNext,
  onChange,
  setShowTimeLimitPopup,
  isChildToAdulthoodSurvey,
  hasDuplicateChildEmailError,
  ...rest
}) => {
  // using state would cause re-renders, which would crash FormIO
  const formData = useRef(null);
  const formRef = useRef(null);
  const scrollTopRef = useRef(0);
  const { activeAccountProfile, person } = useSelector((state) => state.user);

  const handleSave = useCallback(() => {
    document.querySelector('.wizard-content').scrollIntoView(true);
    setTimeout(() => {
      if (isMobile) {
        document.querySelector('.wizard-content').scrollTo(0, 0);
      }
    }, 100);
    onSave({ data: formData.current });
  }, [onSave]);

  const handlePrev = useCallback(() => {
    const ref = document.querySelector('.wizard-footer');
    ref.scrollIntoView(false);
    onSave({ data: formData.current });
  }, [onSave]);

  const handleSubmit = useCallback(
    () => onSubmit({ data: formData.current }),
    [onSubmit]
  );

  const profileRole = activeAccountProfile?.attributes?.profile;
  const studyArmRole =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;
  const personStudyArmId = profileRole?.account_person_study_arm_id;
  const subjectPersonStudyArmId =
    profileRole?.subject_primary_person_study_arm_id;

  const { data: subRoleData, isLoading: isGetSubRoleStudyLoading } =
    useGetPersonStudyArmSubRoleQuery(personStudyArmId);

  const {
    data: reporterAttributesData,
    isLoading: isReporterAttributesLoading,
  } = useGetAllPersonStudyArmAttributesQuery(
    {
      personStudyArmId: subjectPersonStudyArmId,
      'page[limit]': 10000,
    },
    {}
  );

  let subRolesSet = undefined;
  if (subRoleData) {
    subRolesSet = getIParticipantSubroleSet(subRoleData);
  }

  const conditionsMap = {};
  reporterAttributesData?.data?.map((e) => {
    conditionsMap[e?.attributes?.attribute] = e?.attributes?.value === 'true';
  });

  useEffect(() => {
    const { removeListeners } = setupExtraActions({ onCancel });

    return () => {
      removeListeners();
    };
  }, [onCancel]);

  // SING-939: Child to Adulthood Transitioning Duplicate Email Error
  useEffect(() => {
    if (!isChildToAdulthoodSurvey) return;

    let emailErrorTimeout = null;
    if (hasDuplicateChildEmailError) {
      emailErrorTimeout = setTimeout(() => {
        const errorElements = document.getElementsByClassName(
          DUPLICATE_CHILD_EMAIL.CUSTOM_CLASS
        );
        const firstElement = errorElements[0] || null;

        if (firstElement) {
          firstElement.classList.add('has-error', 'has-message');

          // Create a 'p' tag with error message
          const errorMessageTextNode = document.createElement('p');
          errorMessageTextNode.classList.add('form-text', 'error');
          errorMessageTextNode.append(
            document.createTextNode(DUPLICATE_CHILD_EMAIL.UI_ERROR_MSG)
          );

          const errorMessageElement =
            firstElement.querySelector('.formio-errors');
          errorMessageElement.appendChild(errorMessageTextNode);

          firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    } else {
      emailErrorTimeout = setTimeout(() => {
        const errorElements = document.getElementsByClassName(
          DUPLICATE_CHILD_EMAIL.CUSTOM_CLASS
        );
        const firstElement = errorElements[0] || null;

        if (firstElement) {
          firstElement.classList.remove('has-error', 'has-message');
          const errorMessageElement =
            firstElement.querySelector('.formio-errors');
          const errorMessageTextNode = errorMessageElement?.querySelector('p');
          errorMessageTextNode &&
            errorMessageElement?.removeChild(errorMessageTextNode);
        }
      }, 100);
    }

    return () => {
      clearTimeout(emailErrorTimeout);
    };
  }, [isChildToAdulthoodSurvey, hasDuplicateChildEmailError]);

  const handleChange = async ({ data, changed, ...rest }, schema) => {
    formData.current = data;
    onChange(changed);
    if (formRef && formRef.current) {
      const scrollTop =
        document.querySelector('.wizard-content')?.scrollTop ?? undefined;
      if (scrollTop && scrollTopRef) {
        scrollTopRef.current = scrollTop;
      }
      if (!isMobileDevice) {
        const errorElements =
          document.getElementsByClassName('form-text error');

        if (errorElements.length === 0) {
          await formRef.current.triggerRedraw();
        }
      }
    }
  };

  const role = activeAccountProfile?.attributes?.profile?.study_arm_role_name;
  const statusTitle =
    activeAccountProfile?.attributes?.profile?.study_arm_status_title;
  const differentPerson = person.id !== profileRole.subject_primary_person_id;

  let formString = JSON.stringify(form);
  if (differentPerson) {
    const isReporter = role === STUDY_ARM_ROLE_NAME.REPORTER;
    if (isReporter) {
      formString = formString.replace(
        'LAR: Please answer the following questions on behalf of the person diagnosed',
        'Reporter: Please answer the following questions on related to the person diagnosed'
      );
    }
  } else {
    formString = formString.replace(
      'LAR: Please answer the following questions on behalf of the person diagnosed',
      'Participant: Please answer the following questions'
    );
  }

  let newForm = JSON.parse(formString);
  let roleIndex, subRoleIndex, deceasedIndex, ftdTypesIndex;
  newForm.components[0].components.forEach((value, i) => {
    if (value.key === 'role') roleIndex = i;
    if (value.key === 'subRole') subRoleIndex = i;
    if (value.key === 'ftdTypes') ftdTypesIndex = i;
    if (value.key === 'deceased') deceasedIndex = i;
  });

  if (rest.submission?.data?.role === '') {
    rest.submission.data.role = formIOkeyMap[role];
  }
  if (reporterAttributesData) {
    if (
      Number.isInteger(roleIndex) &&
      newForm?.components[0]?.components[roleIndex]?.key === 'role'
    ) {
      newForm.components[0].components[roleIndex].defaultValue =
        formIOkeyMap[role];
    }

    if (
      Number.isInteger(subRoleIndex) &&
      newForm?.components[0]?.components[subRoleIndex]?.key === 'subRole'
    ) {
      if (subRolesSet) {
        const defaultSubRoles = {};
        subRolesSet.forEach((subRole) => {
          const subRoleName = formIOkeyMap[subRole.toLowerCase()];
          if (subRoleName) {
            defaultSubRoles[subRoleName] = true;
          }
        });
        newForm.components[0].components[subRoleIndex].defaultValue = {
          ...defaultSubRoles,
        };
      }
    }

    if (
      Number.isInteger(ftdTypesIndex) &&
      newForm?.components[0]?.components[ftdTypesIndex]?.key === 'ftdTypes'
    ) {
      for (const [name, value] of Object.entries(conditionsMap)) {
        const diagnoseName = DiagnosisKeyMap[name];
        newForm.components[0].components[ftdTypesIndex].defaultValue[
          diagnoseName
        ] = value;
      }
    }

    if (
      Number.isInteger(deceasedIndex) &&
      newForm?.components[0]?.components[deceasedIndex]?.key === 'deceased' &&
      studyArmRole === STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT
    ) {
      newForm.components[0].components[deceasedIndex].defaultValue =
        conditionsMap.participant_deceased;
    } else if (
      Number.isInteger(deceasedIndex) &&
      newForm?.components[0]?.components[deceasedIndex]?.key === 'deceased' &&
      statusTitle === 'deceased'
    ) {
      newForm.components[0].components[deceasedIndex].defaultValue = true;
    }
  }

  const handleComponentChange = (data) => {
    // unselect radio button when clicked on same one which is already checked
    if (data.value === '' && data.instance.type === 'radio') {
      const radioId = `${data.instance.localRoot.id}-${data.instance.id}--${data.instance.currentValue}`;
      const radioElement = document.getElementById(radioId);
      if (!radioElement) {
        return;
      }
      radioElement.parentElement.parentElement.classList.remove(
        'radio-selected'
      );
      radioElement.setAttribute('checked', false);
    }
  };
  const handleErrors = (errors) => {
    setTimeout(() => {
      const errorElements = document.getElementsByClassName('form-text error');
      const firstElement = errorElements[0] || null;

      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  };

  if (isGetSubRoleStudyLoading || isReporterAttributesLoading) {
    return <Loader />;
  }

  let date_of_death;
  if (reporterAttributesData) {
    let result = reporterAttributesData.data?.filter(
      (rad) => rad?.attributes?.attribute === 'date_of_death'
    );

    if (result?.length === 1) {
      date_of_death = result[0]?.attributes?.value;
    }

    const birthDate = activeAccountProfile?.attributes?.profile?.date_of_birth;
    const formattedBirthDate = getFormattedDate(new Date(birthDate));

    const compIndex = newForm?.components[0]?.components?.findIndex((c) => {
      // as_2 is the key of the autopsy survey field for date of death
      return c.key === 'as_2';
    });

    if (compIndex && compIndex > 0) {
      newForm.components[0].components[compIndex].enableMinDateInput = true;
      newForm.components[0].components[compIndex]['customOptions'] = {
        minDate: formattedBirthDate,
      };

      if (date_of_death) {
        newForm.components[0].components[compIndex].defaultValue =
          date_of_death;
      }
    }
  }

  return (
    <Form
      onRender={() => {
        if (document.querySelector('.wizard-content')) {
          document.querySelector('.wizard-content').scrollTop =
            scrollTopRef?.current;
        }
        setupExtraActions({
          onCancel,
        });
      }}
      formReady={(form) => {
        formRef.current = form;
      }}
      onComponentChange={handleComponentChange}
      onPrevPage={() => handlePrev()}
      onNextPage={() => handleSave()}
      onSubmit={() => handleSubmit()}
      onError={handleErrors}
      onChange={handleChange}
      form={{
        ...newForm,
        components: newForm.components.map((comp) => {
          return { ...comp, title, description };
        }),
        title,
        isMobile: isMobile,
        rewardPoints: compensationValue,
        estimatedTime,
        type: taskTypeLabelMapping[type],
        timeLimit,
      }}
      options={{ i18n }}
      {...rest}
    />
  );
};

export default FormIO;
