import { Box } from '@mui/material';
import ProtectedRoute from 'authentication/ProtectedRoute';
import RequireAuth from 'authentication/requireAuth';
import Login from 'pages/Login';
import { Navigate, Route, Routes } from 'react-router-dom';

import HealthCheck from 'pages/Health';
import Final from 'pages/Health/final';
import InformedConsent from 'pages/InformedConsent';
import LoginWithGoogle from 'pages/Login/google';
import Main from 'pages/Main';
import ParticipantConsentDetails from 'pages/Onboarding/ConsentDetails';
import ParticipantConsents from 'pages/Onboarding/Consents';
import OnlyDesktop from 'pages/OnlyDesktop';
import Profile from 'pages/Profile';
import Achievements from 'pages/Profile/Achievements';
import MyStudy from 'pages/Profile/MyStudy';
import Payment from 'pages/Profile/Payment';
import StatusChange from 'pages/Profile/StatusChange';
import StudyWithdrawal from 'pages/Profile/StudyWithdrawal';
import Done from 'pages/Providers/Final';
import List from 'pages/Providers/List';
import Study from 'pages/Study';
import StudyOverview from 'pages/StudyOverview';
import FinalPage from 'pages/SyncData/FinalPage';
import SearchProvidersV2 from 'pages/SyncData/SearchProvidersV2';
import PersonalInformation from 'pages/User';
import UserBasicInfo from 'pages/User/BasicInfo';
import ConfirmPersonalInformation from 'pages/User/confirm';
import ViewConsent from 'pages/ViewConsent';

import NotificationCenter from 'pages/NotificationCenter';
import MedicationTracker from 'pages/NotificationCenter/MedicationTracker';
import AllMedications from 'pages/NotificationCenter/MedicationTracker/AllMedications';
import CreateMedicationTracker from 'pages/NotificationCenter/MedicationTracker/Create';
import DetailedMedicationTracker from 'pages/NotificationCenter/MedicationTracker/Detailed';
import MedicationSearch from 'pages/NotificationCenter/MedicationTracker/Search';
import PregnancyJournal from 'pages/NotificationCenter/PregnancyJournal';
import CreatePregnancyJournalDay from 'pages/NotificationCenter/PregnancyJournal/Create';
import PregnancyJournalDetailed from 'pages/NotificationCenter/PregnancyJournal/Detailed';

import Article from 'pages/Activities/Article';
import ActivitiesList from 'pages/Activities/List';
import MakeCall from 'pages/Activities/MakeCall';
import ScheduleCall from 'pages/Activities/ScheduleCall';
import Survey from 'pages/Activities/Survey';
import Video from 'pages/Activities/Video';
import Home from 'pages/Home';

import ConsentDetails from 'pages/Profile/Consents/Details';
import ConsentsList from 'pages/Profile/Consents/List';
import HyperwalletMain from 'pages/Profile/HyperWallet';
import HyperWalletCallback from 'pages/Profile/HyperWallet/callback';
import HyperWalletAccount from 'pages/Profile/HyperWallet/CreateUser';
import HyperWalletTransfer from 'pages/Profile/HyperWallet/Transfer';
import HyperWalletVerify from 'pages/Profile/HyperWallet/Verify';
import Welcome from 'pages/Welcome';

import { LandingLayout } from 'components/LandingLayout';
import About from 'pages/about';
import StandaloneSurvey from 'pages/Activities/Survey/Standalone';
import Auth0Callback from 'pages/Auth0Callback';
import Auth0EmailVerificationFailed from 'pages/Auth0EmailVerificationFailed';
import Auth0EmailVerify from 'pages/Auth0EmailVerify';
import Auth0EmailUnblock from 'pages/Auth0EmailUnblock';
import Clinicians from 'pages/clinicians';
import CommunicationPage from 'pages/Communication';
import ConfigParser from 'pages/ConfigParser/ConfigParser';
import ContactUs from 'pages/contact-us';
import { EducationalMaterials } from 'pages/EducationalMaterials';
import EmailVerified from 'pages/EmailVerified';
import HealthQuestionnairePage from 'pages/EQ5D5L';
import FAQ from 'pages/FAQ';
import GetInvolved from 'pages/GetInvolved';
// import AboutRegistryPage from 'pages/Onboarding/AboutRegistry';
import AddReporterPage from 'pages/Onboarding/AddReporter';
import BasicInformationPage from 'pages/Onboarding/BasicInformation';
import ConsentSignedPage from 'pages/Onboarding/ConsentSigned';
import CreateProfile from 'pages/Onboarding/CreateProfile';
import { default as UserEnrolled } from 'pages/Onboarding/Enrolled';
import EnrollmentFeedback from 'pages/Onboarding/EnrollmentFeedback';
import LanguageOnboard from 'pages/Onboarding/Language';
import NoActiveProfile from 'pages/Onboarding/NoActiveProfile';
import NoRoleApplicable from 'pages/Onboarding/NoRoleApplicable';
import NotEligiblePage from 'pages/Onboarding/NotEligible';
import PersonalInformationDetails from 'pages/Onboarding/PersonalInformation';
// import RegistryOptionPage from 'pages/Onboarding/RegistryOption';
// import ResearchEligibilityRoleCareGiver from 'pages/Onboarding/ResearchEligibilityRoleCareGiver';
// import ResearchRegistryNotEligible from 'pages/Onboarding/ResearchRegistryNotEligible';
import WelcomeOnboard from 'pages/Onboarding/WelcomeOnboard';
import WelcomeToStudy from 'pages/Onboarding/WelcomeToStudy';
// import InterestsCategory1 from 'pages/Onboarding/YourInterests/category-1';
// import InterestsCategory2 from 'pages/Onboarding/YourInterests/category-2';
// import InterestsCategory3 from 'pages/Onboarding/YourInterests/category-3';
// import InterestsCategory4 from 'pages/Onboarding/YourInterests/category-4';
import MyProviders from 'pages/Profile/MyProviders';
import Redeem from 'pages/Profile/Redeem';
import Rejected from 'pages/Rejected';
import { ResearchOpportunities } from 'pages/ResearchOpportunities';
import ResearchOpportunityDetailsPage from 'pages/ResearchOpportunities/ResearchOpportunityDetailsPage';
import SpecificFinalPage from 'pages/SyncData/FinalPage/specific-final';
import ProviderPage from 'pages/SyncData/ProviderPage';
import AdditionalInformation from 'pages/User/AdditionalInformation';
import AlternativeContact from 'pages/User/AlternativeContact';
import CreateUser from 'pages/User/Create';
import Doctors from 'pages/User/Doctors';
import VerifyEmail from 'pages/VerifyEmail';
import WelcomeAboard from 'pages/WelcomeAboard';
import WithdrawnFromStudy from 'pages/WithdrawnFromStudy';
import EducationalMaterialDetailsPage from '../pages/EducationalMaterialDetails';
import ParticipantInformationPage from '../pages/Onboarding/ParticipantInformation/ParticipantInformationPage';
// import ResearchEligibility from '../pages/Onboarding/ResearchEligibility';
import ResearchEligibilityRole from '../pages/Onboarding/ResearchEligibilityRole/ResearchEligibilityRole';
import SelectProfile from '../pages/Onboarding/SelectProfile';
import AccountHolderProfile from '../pages/Profile/AccountHolderProfile';
import ParticipantProfile from '../pages/Profile/ParticipantProfile';
import RegistryStatistics from '../pages/RegistryStatistics';
// import Privacy from '../pages/TermsAndPrivacy/privacy';
// import Terms from '../pages/TermsAndPrivacy/terms';
import { AdditionalResourcesPage } from 'pages/Onboarding/AdditionalResources';
import { useEffect } from 'react';
import { setNavbarExpanded } from 'ihp-bloom-redux/features/uiComponents/navbarSlice';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { Resources } from 'pages/Welcome/Resources';
import { Resource } from 'pages/Welcome/Resource';
import { FamilyLinkagePage } from 'pages/Profile/FamilyLinkage';
import QuickEnrollment from 'pages/QuickEnrollment';

export function AppRoutes() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavbarExpanded(true));
  }, []);
  return (
    <Routes>
      <Route path='*' element={<Box>Page not found: 404!</Box>} />
      {/* <Route path='authorize' element={<Login />} /> */}
      <Route path='login' element={<Login />} />
      <Route path='quick-enrollment' element={<QuickEnrollment />} />

      {/* Contains SV Source Registry data */}
      {/* <Route path='terms' element={<Terms />} /> */}
      {/* <Route path='privacy' element={<Privacy />} /> */}

      {/* <Route path='login/email' element={<LoginWithEmail />} /> */}
      <Route path='login/google' element={<LoginWithGoogle />} />
      <Route path='welcome' element={<LandingLayout />}>
        <Route path='' element={<Welcome />} />
        <Route path='get-involved' element={<GetInvolved />} />
        <Route path='about' element={<About />} />
        <Route path='for-clinicians' element={<Clinicians />} />
        <Route path='faq' element={<FAQ />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='resources' element={<Resources />} />
        <Route path='resources/:id' element={<Resource />} />
      </Route>

      <Route path='welcome-aboard' element={<WelcomeAboard />} />
      <Route path='email-verified' element={<EmailVerified />} />
      <Route path='withdrawn-from-study' element={<WithdrawnFromStudy />} />
      <Route
        path='auth0-emailunblock'
        element={<Auth0EmailUnblock />}
      />
      <Route
        path='auth0-emailverify'
        element={<ProtectedRoute component={Auth0EmailVerify} />}
      />
      <Route
        path='auth0-callback'
        element={<ProtectedRoute component={Auth0Callback} />}
      />
      <Route
        path='create-user'
        element={<ProtectedRoute component={CreateUser} />}
      />
      <Route path='/' element={<RequireAuth />} />
      <Route path='/basic-info' element={<UserBasicInfo />} />
      <Route path='verify-email' element={<VerifyEmail />} />
      <Route
        path='verification-failed'
        element={<ProtectedRoute component={Auth0EmailVerificationFailed} />}
      />
      <Route path='rejected' element={<Rejected />} />
      <Route
        path='personalinformation'
        element={<ProtectedRoute component={PersonalInformation} />}
      />
      <Route
        path='confirmpersonalinformation'
        element={<ProtectedRoute component={ConfirmPersonalInformation} />}
      />
      <Route
        path='viewconsent'
        element={<ProtectedRoute component={ViewConsent} />}
      />
      <Route
        path='informedconsent'
        element={<ProtectedRoute component={InformedConsent} />}
      />
      <Route
        path='select-profile'
        element={<ProtectedRoute component={SelectProfile} />}
      />
      <Route
        path='no-active-profile'
        element={<ProtectedRoute component={NoActiveProfile} />}
      />
      <Route element={<ProtectedRoute component={Main} navigator={true} />}>
        <Route path='profile' element={<ProtectedRoute component={Profile} />}>
          <Route
            path='payment/hyperwallet/account'
            element={<ProtectedRoute component={HyperWalletAccount} />}
          />
          <Route
            path='payment/hyperwallet/transfer'
            element={<ProtectedRoute component={HyperWalletTransfer} />}
          />
          <Route
            path='payment/hyperwallet/verify'
            element={<ProtectedRoute component={HyperWalletVerify} />}
          />
        </Route>
      </Route>
      <Route element={<ProtectedRoute component={Main} />}>
        <Route path='registry-statistics' element={<RegistryStatistics />} />
        <Route path='/home' element={<Home />} />
        <Route path='/education-materials' element={<EducationalMaterials />} />
        <Route
          path='/education-materials/:id'
          element={<EducationalMaterialDetailsPage />}
        />
        <Route
          path='/research-opportunities'
          element={<ResearchOpportunities />}
        />
        <Route
          path='/research-opportunities/:id'
          element={<ResearchOpportunityDetailsPage />}
        />
        <Route
          path='/my-activities'
          element={<ProtectedRoute component={ActivitiesList} />}
        />
        <Route path='switch-profile' element={<SelectProfile />} />
        <Route path='survey' element={<Box>survey</Box>} />
        <Route path='health-survey/:id' element={<HealthQuestionnairePage />} />
        <Route path='beats' element={<Box>beats</Box>} />
        <Route path='sync-data/list' element={<List />} exact />
        <Route
          path='tools'
          element={<ProtectedRoute component={NotificationCenter} />}
        >
          <Route path='medication-tracker'>
            <Route
              path='all'
              element={<ProtectedRoute component={AllMedications} />}
            />
            <Route
              path='reminders/:id'
              element={<ProtectedRoute component={DetailedMedicationTracker} />}
            >
              <Route
                path='edit'
                element={<ProtectedRoute component={CreateMedicationTracker} />}
              />
            </Route>
            <Route
              path=''
              element={<ProtectedRoute component={MedicationTracker} />}
            >
              <Route
                path='search'
                element={<ProtectedRoute component={MedicationSearch} />}
              />
              <Route
                path='create'
                element={<ProtectedRoute component={CreateMedicationTracker} />}
              />
            </Route>
          </Route>
          <Route path='pregnancy-journal'>
            <Route
              path=''
              element={<ProtectedRoute component={PregnancyJournal} />}
            />
            <Route
              path='create'
              element={<ProtectedRoute component={CreatePregnancyJournalDay} />}
            />
            <Route path='' element={<PregnancyJournal />} />
            <Route path='create' element={<CreatePregnancyJournalDay />} />
            <Route path=':id' element={<PregnancyJournalDetailed />} />
          </Route>
        </Route>
        <Route
          path='payment'
          element={<ProtectedRoute component={Payment} />}
        />
        <Route path='profile' element={<ProtectedRoute component={Profile} />}>
          <Route
            path='payment/hyperwallet'
            element={<ProtectedRoute component={HyperwalletMain} />}
          />
          <Route
            path='hyperwallet-callback'
            element={<ProtectedRoute component={HyperWalletCallback} />}
          />
          <Route path='consents'>
            <Route
              path=''
              element={<ProtectedRoute component={ConsentsList} />}
            />
          </Route>
          <Route
            path='my-achievements'
            element={<ProtectedRoute component={Achievements} />}
          />
          <Route
            path='my-providers'
            element={<ProtectedRoute component={MyProviders} />}
          />
          <Route
            path='provider'
            element={<ProtectedRoute component={ProviderPage} />}
          />
          <Route
            path='my-profile'
            element={<Navigate to='/profile/accountholder-profile' replace />}
          />
          <Route
            path='participant-profile'
            element={<ProtectedRoute component={ParticipantProfile} />}
          />
          <Route
            path='accountholder-profile'
            element={<ProtectedRoute component={AccountHolderProfile} />}
          />
          <Route
            path='my-study'
            element={<ProtectedRoute component={MyStudy} />}
          />
          <Route
            path='study-withdrawal'
            element={<ProtectedRoute component={StudyWithdrawal} />}
          />
          <Route
            path='change-status'
            element={<ProtectedRoute component={StatusChange} />}
          />
          <Route
            path='family'
            element={<ProtectedRoute component={FamilyLinkagePage} />}
          />
        </Route>
        <Route path='progress' element={<ProtectedRoute component={Study} />} />
      </Route>
      <Route path='connect' element={<OnlyDesktop />}>
        <Route
          path='complete'
          element={<ProtectedRoute component={Done} />}
          exact
        />
      </Route>
      <Route path='health-check' element={<HealthCheck />} />
      <Route path='final' element={<ProtectedRoute component={Final} />} />
      <Route
        path='studyoverview'
        element={<ProtectedRoute component={StudyOverview} />}
      />
      <Route element={<OnlyDesktop />}>
        <Route
          path='callback'
          element={<ProtectedRoute component={SpecificFinalPage} />}
        />
      </Route>
      <Route path='activity' element={<OnlyDesktop />}>
        <Route
          path='article/:id'
          element={<ProtectedRoute component={Article} />}
        />
        <Route
          path='survey/:id'
          element={<ProtectedRoute component={Survey} />}
        />
        <Route
          path='sync-data'
          element={<ProtectedRoute component={SearchProvidersV2} />}
        />
        <Route
          path='provider'
          element={<ProtectedRoute component={ProviderPage} />}
        />
        <Route
          path='sync-data-final'
          element={<ProtectedRoute component={FinalPage} />}
        />
        <Route
          path='video/:id'
          element={<ProtectedRoute component={Video} />}
        />
        <Route
          path='schedule-call/:id'
          element={<ProtectedRoute component={ScheduleCall} />}
        />
        <Route
          path='make-call/:id'
          element={<ProtectedRoute component={MakeCall} />}
        />
      </Route>
      <Route path='onboarding'>
        <Route
          path='research-eligibility/role'
          element={<ProtectedRoute component={ResearchEligibilityRole} />}
        />
        <Route
          path='research-eligibility/role/no-role-applicable'
          element={<ProtectedRoute component={NoRoleApplicable} />}
        />
        {/* <Route
          path='research-eligibility/role/caregiver'
          element={
            <ProtectedRoute component={ResearchEligibilityRoleCareGiver} />
          }
        /> */}
        <Route
          path='participant-consents'
          element={<ProtectedRoute component={ParticipantConsents} />}
        />
        <Route
          path='participant-consents/:participantTaskId/:consentVersionId'
          element={<ProtectedRoute component={ParticipantConsentDetails} />}
        />
        <Route path='welcome-to-study' element={<WelcomeToStudy />} />
        <Route path='language' element={<LanguageOnboard />} />
        <Route
          path='welcome-onboard'
          element={<ProtectedRoute component={WelcomeOnboard} />}
        />
        <Route
          path='enrolled'
          element={<ProtectedRoute component={UserEnrolled} />}
        />
        <Route
          path='enrollment-feedback'
          element={<ProtectedRoute component={EnrollmentFeedback} />}
        />
        <Route
          path='create-profile'
          element={<ProtectedRoute component={CreateProfile} />}
        />
        <Route
          path='additionalinformation'
          element={<ProtectedRoute component={AdditionalInformation} />}
        />
        <Route
          path='alternativecontact'
          element={<ProtectedRoute component={AlternativeContact} />}
        />
        <Route
          path='doctors'
          element={<ProtectedRoute component={Doctors} />}
        />
        <Route
          path='communication'
          element={<ProtectedRoute component={CommunicationPage} />}
        />
        <Route
          path='verify-email'
          element={<ProtectedRoute component={VerifyEmail} />}
        />
        <Route path='basic-information' element={<BasicInformationPage />} />
        <Route
          path='additional-resources'
          element={<AdditionalResourcesPage />}
        />
        <Route path='not-eligible' element={<NotEligiblePage />} />
        {/* <Route
          path='your-interests-1'
          element={<ProtectedRoute component={InterestsCategory1} />}
        /> */}
        {/* <Route
          path='your-interests-2'
          element={<ProtectedRoute component={InterestsCategory2} />}
        /> */}
        {/* <Route
          path='your-interests-3'
          element={<ProtectedRoute component={InterestsCategory3} />}
        /> */}
        {/* <Route
          path='your-interests-4'
          element={<ProtectedRoute component={InterestsCategory4} />}
        /> */}
        <Route
          path='participant-information'
          element={<ProtectedRoute component={ParticipantInformationPage} />}
        />
        {/* <Route
          path='registry-option'
          element={<ProtectedRoute component={RegistryOptionPage} />}
        /> */}
        {/* <Route
          path='about-registry'
          element={<ProtectedRoute component={AboutRegistryPage} />}
        /> */}
        <Route
          path='add-reporter'
          element={<ProtectedRoute component={AddReporterPage} />}
        />
        <Route
          path='personal-information'
          element={<ProtectedRoute component={PersonalInformationDetails} />}
        />
        {/* <Route
          path='research-eligibility'
          element={<ProtectedRoute component={ResearchEligibility} />}
        /> */}
        <Route
          path='research-registry-notEligible'
          // SING-545
          // element={<ProtectedRoute component={ResearchRegistryNotEligible} />}
          element={<ProtectedRoute component={NotEligiblePage} />}
        />
      </Route>

      <Route path='profile' element={<OnlyDesktop />}>
        <Route
          path='consents/:consentId/:consentVersionId'
          element={<ProtectedRoute component={ConsentDetails} />}
        />
      </Route>
      <Route path='standalone-survey' element={<StandaloneSurvey />} />
      <Route path='redeem' element={<Redeem />} />
      <Route
        path='consent-signed'
        element={<ProtectedRoute component={ConsentSignedPage} />}
      />
      <Route path='config-parser' element={<ConfigParser />} />
    </Routes>
  );
}
