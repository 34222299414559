import { Loader } from 'components/Loader';
import { useLocation, useNavigate } from 'react-router-dom';

import { getNavigationUrl } from 'utils/authenticate';
import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';
import { useSelector } from 'ihp-bloom-redux/app/redux';

const withPersonStatusCheck = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { person, activeAccountProfile } = useSelector((state) => state.user);

    const {
      accountProfile,
      isLoadingAccountProfileDetails: isLoadingAccountProfile,
    } = useGetAccountProfileDetails({
      personId: person?.id,
      accountProfileId: activeAccountProfile?.id,
    });

    const url = getNavigationUrl(accountProfile);

    if (isLoadingAccountProfile) {
      return <Loader />;
    }

    if (url && url !== location?.pathname) {
      navigate(url);
    }

    return <WrappedComponent {...props} />;
  };
};

export default withPersonStatusCheck;
