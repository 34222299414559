import { useGetDistributionOfGeneInheritanceMutation } from 'ihp-bloom-redux/features/registryStatistics/registryStatisticsApi';
import { useEffect, useState } from 'react';
import { theme } from '../../../theme';
import ReactECharts from 'echarts-for-react';
import { Loader } from 'components/Loader';
import { ChartContainer } from './styles';
import { formatLabelText } from '../../../utils/chartUtils';
import { LABELS } from 'utils/constants';

const DistributionOfGeneInheritanceChart = () => {
  const [getChartData, result] = useGetDistributionOfGeneInheritanceMutation();

  const [chartOptions, setChartOptions] = useState({});

  const [showChart, setShowChart] = useState(false);

  const getChartOptions = (xAxis, yAxis, total) => {
    return {
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          return `<div>
            <div style="font-family:'Inter';white-space: normal;;width: ${
              window.screen.width < 600 ? '100px' : '200px'
            }">${params[0].name}</div>
            <div style="display:flex;align-items:center;">
            <p style="display:inline-block;margin-right:10px;width:16px;height:16px;border-radius: 8px;background-color:${
              theme.palette.primary.darkblue100
            }"></p>
            <p style="display:inline-block;text-align: left;">${
              params[0].value
            }</p>
            </div></div>`;
        },
      },
      grid: {
        top: '10%',
        right: '10%',
        containLabel: true,
      },
      title: {
        text: 'Reported conditions\n',
        subtext: [`Total = ${total}`],
        textStyle: {
          color: theme.palette.primary.gray75,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: 500,
        },
        subtextStyle: {
          color: theme.palette.primary.gray75,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
      xAxis: {
        type: 'value',
        color: theme.palette.primary.gray75,
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500,
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'category',
        data: yAxis,
        axisLabel: {
          fontFamily: 'Inter',
          fontSize: '12px',
          formatter: function (value) {
            return formatLabelText(value, 20, 35, '\n\n');
          },
        },
      },
      series: [
        {
          data: xAxis,
          type: 'bar',
          color: theme.palette.primary.darkblue100,
          label: {
            width: '206px',
            show: true,
            position: 'right',
            color: theme.palette.primary.gray75,
            fontStyle: 'normal',
            fontSize: '12px',
            fontWeight: 400,
            formatter: (value) => (value.value < 15 ? '<15' : value.value),
          },
        },
      ],
    };
  };

  useEffect(() => {
    getChartData();
  }, []);

  useEffect(() => {
    const edges =
      result?.data?.data?.vw_dtp_registry_participants_conditions?.edges;
    if (!edges) {
      return;
    }
    const total = edges.reduce(
      (acc, item) => acc + item?.node?.count_participant,
      0
    );
    // if (total < 100) {
    //   setShowChart(false);
    //   return;
    // } else {
    setShowChart(true);
    // }
    const xAxis = [];
    const yAxis = [];
    edges?.forEach((item) => {
      xAxis.push(item?.node?.count_participant);
      // SING-964
      yAxis.push(item?.node?.reported_condition ?? LABELS.UNKNOWN);
    });

    const chartOptionsData = getChartOptions(xAxis, yAxis, total);
    setChartOptions(chartOptionsData);
  }, [result]);

  if (result.isLoading) {
    return <Loader />;
  }

  if (!showChart) {
    return <></>;
  }

  return (
    <ChartContainer>
      <ReactECharts
        option={chartOptions}
        style={{ height: '100%', width: '100%' }}
      />
    </ChartContainer>
  );
};

export default DistributionOfGeneInheritanceChart;
