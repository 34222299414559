import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
    useCreateAccountProfileMutation,
    useGetAccountProfileQuery,
} from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { Loader } from 'components/Loader';
import { STUDY_ARM_ROLE_CODES, STUDY_ARM_STATUS_CODES, VIRTUAL_SITE } from 'constants/global';
import { useConfiguration } from 'pages/Onboarding/ResearchEligibilityRole/hooks/useConfiguration';
import { isValidString } from 'utils/string';
import { useLogout } from 'utils/useLogout';

const makeStudyArmAttributePayload = (key, value) => ({
    data: {
        type: 'person-attributes',
        attributes: {
            attribute: key,
            value: value,
        },
    },
});

export const bestDescribeOptions = Object.freeze([
    'I was born with a single ventricle heart defect',
    'My biological immediate family member is living with a single ventricle heart defect',
    'I am a guardian of someone living with a single ventricle heart defect',
    'I am a guardian of someone whose biological immediate relative is living with a single ventricle heart defect',
    'I have lost a family member with a single ventricle heart defect',
    'None of these apply to me',
]);

/*
 * This callback route has 2 responsibilities
 * Trigger "create account profile" api call and save response in store (redux)
 * Trigger "get account profile details" api using "account_profile_id" from above api response and save in store (redux)
 */

function CreateUser({ configuration }) {
    const { user } = useAuth0();
    const logout = useLogout();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { accountProfileId, reporterInviteToken, person } = useSelector(
        (state) => state.user
    );

    //   const { configuration } = useSelector((state) => state.configuration);

    const [createPersonAttributes] = useEditPersonAttributesMutation();

    /* This will be "true" once account profile details are fetched and saved in store*/
    const [accountProfileSynced, setAccountProfileSynced] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [createAccountProfileMutation, { isLoading: isCreatingAccount }] =
        useCreateAccountProfileMutation();

    const {
        data: accountProfileDetails,
        isError, // yet to decided how to handle
        isLoading: isLoadingAccountProfileDetails,
    } = useGetAccountProfileQuery(accountProfileId, {
        skip: !accountProfileId,
    });

    const {
        studyArms,
        studyArmStatuses,
        studyArmRoles,
        getResearchStudyArm,
        getContactStudyArm,
    } = configuration;
    console.log('configuration #2' ,configuration);
    useEffect(() => {
        if (person?.id) {
            navigate('/auth0-callback');
            return;
        }



        async function initiateProcess() {
            // If role is eligible then enroll into research otherwise enroll into contact
            const prescreeningData = JSON.parse(
                decodeURIComponent(
                    user['https://ihp-bloom-app/user_metadata.prescreening_data']
                )
            );
            const isEligible = prescreeningData.is_eligible;

            // Find study arm ID
            const studyArmId = isEligible
                ? getResearchStudyArm().id
                : getContactStudyArm().id;
                
            let siteId = null;
            let studyArmStatusCode = null;
            let studyArmRoleCode = null;
            // check if invite_token starts with enrolled_site
            if(reporterInviteToken && reporterInviteToken.startsWith('enrolled_site')) {
                studyArmRoleCode = STUDY_ARM_ROLE_CODES.Pending;
                studyArmStatusCode = STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED;
                // Site ID is only validated but not updated when invite_token is present
                siteId = VIRTUAL_SITE.id;
            } else {
                if(isEligible) {
                    studyArmRoleCode = STUDY_ARM_ROLE_CODES.Pending;
                    studyArmStatusCode = STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_ACC_CREATED;
                } else {
                    studyArmRoleCode = STUDY_ARM_ROLE_CODES.Participant;
                    studyArmStatusCode = STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_ACC_CREATED;
                }
                siteId = VIRTUAL_SITE.id;
            }

            const studyArmRoleId = studyArmRoles.find(
                (role) => role.code === studyArmRoleCode && role.study_arm_id === studyArmId
            ).id;

            let studyArmStatusId = studyArmStatuses.find(
                (status) =>
                    status.code === studyArmStatusCode &&
                    status.study_arm_id === studyArmId
            )?.id;

            const payload = {
                data: {
                    type: 'profiles',
                    attributes: {
                        first_name: user.given_name,
                        last_name: user.family_name,
                        email: user.email,
                        terms_and_conditions: true,
                        privacy_policy: true,
                        study_arm_check: studyArmId,
                        study_arm: studyArmId,
                        study_arm_role: studyArmRoleId,
                        study_arm_status: studyArmStatusId,
                        site: siteId,
                        provider_id: user.sub,
                    },
                },
            };

            if (reporterInviteToken) {
                payload.data.attributes.invite_token = reporterInviteToken;
            }

            
            try {
                const resp = await createAccountProfileMutation(payload);

                // Create person study arm attributes (date of birth and options etc)

                if (resp?.error) {
                    // TODO: Fix me with proper generic way to identify user already created maybe API should send some generic message or code specific for this error
                    const expectedErrorMessage = 'provider id has already been taken';
                    const errorDetail = resp?.error?.data?.errors?.[0]?.detail;
                    if (errorDetail?.includes(expectedErrorMessage)) {
                        navigate('/auth0-callback');
                    } else {
                        setShowToast(true);
                        logout();
                    }
                }
                if (resp?.data) {
                    const { data } = resp.data;

                    const personAtttributesPayload = [];
                    if ("country" in prescreeningData) {
                        personAtttributesPayload.push(makeStudyArmAttributePayload('country', prescreeningData.country));
                    }
                    if ("date_of_birth" in prescreeningData) {
                        personAtttributesPayload.push(makeStudyArmAttributePayload('date_of_birth', prescreeningData.date_of_birth));
                    }
                    if ("best_describe_attributes" in prescreeningData && Array.isArray(prescreeningData.best_describe_attributes)) {
                        personAtttributesPayload.push(...prescreeningData.best_describe_attributes.map((attr) => makeStudyArmAttributePayload(attr, true)).flat());
                    }

                    if (isValidString(prescreeningData.state)) {
                        personAtttributesPayload.push(
                            makeStudyArmAttributePayload('state', prescreeningData.state)
                        );
                    }

                    if(personAtttributesPayload.length > 0) {
                        await createPersonAttributes({
                            personId: data.attributes.person_id,
                            payload: personAtttributesPayload,
                        });
                    }

                    dispatch(
                        setValues({
                            reporterInviteToken: null,
                            userId: data?.attributes?.user_id,
                            person: data?.attributes?.person_id
                                ? { id: data?.attributes?.person_id }
                                : null,
                            accountProfileId: data?.attributes?.account_profile_id,
                        })
                    );
                    setUserCreated(true);
                }
            } catch (error) {
                console.log('Error: ', error);
                setShowToast(true);
                logout();
            }
        }
        if (!userCreated) {
            initiateProcess();
        }
    }, []);

    /*
     * As soon as we get account profile details we will save it into redux store
     * so following screens can start reading those details from that location
     */
    useEffect(() => {
        if (accountProfileDetails?.data) {
            dispatch(
                setValues({
                    activeAccountProfile: accountProfileDetails.data,
                })
            );
            setAccountProfileSynced(true);
        }
    }, [accountProfileDetails]);

    if (isError) {
        setShowToast(true);
        logout();
    }

    const isLoading = isCreatingAccount || isLoadingAccountProfileDetails;

    if (!user?.email_verified && userCreated && accountProfileSynced) {
        return <Navigate to='/verify-email' />;
    }

    return (
        <div>
            {isLoading && <Loader />}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showToast}
                autoHideDuration={6000}
            >
                <Alert severity='error'>Something went wrong...</Alert>
            </Snackbar>
        </div>
    );
}

const withStudyArmsHoc = (Component) => (props) => {
    const [configurationsState, setConfigurationsState] = useState(null);
    const configurations = useConfiguration();
    
    // for some reasons configurations keep changing causing unwanted re-renders randomly breaking the create user flow
    useEffect(() => {
        const {studyArms} = configurations;
        if (!configurationsState && studyArms) {
            setConfigurationsState(configurations);
        }
    }, [configurations, configurationsState]);
    
    if(!configurationsState) {
        return <Loader />
    }

    console.log('configuration #1' ,configurationsState);
    return <Component {...props} configuration={configurationsState} />
};

export default withStudyArmsHoc(CreateUser);
