import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import ProfileList from 'components/SelectProfile/ProfileList';

import {
    Container,
    Row,
    PageTitle,
    HelperText,
    SelectProfileWrapper,
    getStyles,
} from './styles';

import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import getRoute from 'utils/navigator';
import { STUDY_ARM_STATUS_CODES } from 'constants/global';
import { familyApiSlice } from 'ihp-bloom-redux/features/family/familyApiSlice';

function SelectProfile() {
    document.title = 'Single Ventricle SOURCE Select Profile';
    const styles = getStyles();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const { activeAccountProfile, person } = user;

    const [selectedProfileId, setSelectedProfileId] = useState(null);

    const { configuration } = useSelector((state) => state.configuration);

    const enrolledSiteStatusCode = STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED_SITE;
    const enrolledSiteStatusId = configuration["study-arm-statuses"]?.[enrolledSiteStatusCode]?.id;
    
    const enrolledStatusCode = STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED;
    const enrolledStatusId = configuration["study-arm-statuses"]?.[enrolledStatusCode]?.id;

    const {
        isLoadingAccountProfileDetails,
        accountProfiles,
        accountProfile,
        noActiveProfiles
    } = useGetAccountProfileDetails({
        personId: person?.id,
        shouldSelectDefaultProfile: false,
        accountProfileId: selectedProfileId,
    });

    const routeMap = {
        '/select-profile': {
            description:
                'Multiple profiles are associated with this email. Select the profile you would like proceed with.',
            initialValue: accountProfiles[0]?.attributes?.id,
        },
        '/switch-profile': {
            description: 'Select the profile you would like to proceed with.',
            initialValue: activeAccountProfile?.attributes?.profile?.id,
        },
    };
    const { description = '', initialValue } = routeMap[pathname] || {};
    const [selectedOption, setSelectedOption] = useState(null);

    const handleContinue = () => {
        setSelectedProfileId(selectedOption);
        sessionStorage.setItem('isFirstTimeLogin', 'true')
        sessionStorage.removeItem('isRequiredActivitiesCompleted');
    };

    const [updatePersonStudyArm, { isLoading: isUpdating }] = useUpdatePersonStudyArmMutation();
    const [isSwitchingProfile, setIsSwitchingProfile] = useState(false);
    useEffect(() => {
        const updateStudyArmStatus = async () => {
            if (!accountProfile || isSwitchingProfile) return;
            setIsSwitchingProfile(true);

            dispatch(setValues({ activeAccountProfile: accountProfile?.data }));
            const { study_arm_status_id, subject_primary_person_study_arm_id, account_person_study_arm_id } = accountProfile?.data?.attributes.profile || {};
            if (study_arm_status_id === enrolledSiteStatusId) {
                await handleEnrolledSiteStatus(subject_primary_person_study_arm_id, account_person_study_arm_id);
            } else {
                navigateToProfile(accountProfile?.data);
            }
            setIsSwitchingProfile(false);
        };

        updateStudyArmStatus();
    }, [accountProfile, dispatch, navigate, updatePersonStudyArm, enrolledSiteStatusId, enrolledStatusId, user, activeAccountProfile, configuration, enrolledStatusCode]);


    const handleEnrolledSiteStatus = async (subjectPrimaryId, accountPersonId) => {
        const createUpdatePayload = (id) => ({
            data: {
                type: 'person-study-arms',
                id: `${id}`,
                attributes: { study_arm_status_id: enrolledStatusId },
            },
        });

        const promises = [
            updatePersonStudyArm({
                personStudyArmId: subjectPrimaryId,
                payload: createUpdatePayload(subjectPrimaryId),
            }),
        ];

        if (subjectPrimaryId !== accountPersonId) {
            promises.push(
                updatePersonStudyArm({
                    personStudyArmId: accountPersonId,
                    payload: createUpdatePayload(accountPersonId),
                })
            );
        }

        await Promise.all(promises);
        const activeProfile = accountProfile?.data;
        const updatedProfileObj = {
            ...activeProfile,
            attributes: {
                ...activeProfile.attributes,
                profile: {
                    ...activeProfile.attributes.profile,
                    study_arm_status_id: enrolledStatusId,
                    study_arm_status_title: configuration["study-arm-statuses"][enrolledStatusCode].title,
                },
            },
        };

        // Invalidate FamilyMembers tag to refetch family members [SING-1303]
        dispatch({
            type: `${familyApiSlice.reducerPath}/invalidateTags`,
            payload: ['FamilyMembers'],
        });
        dispatch(setValues({ activeAccountProfile: updatedProfileObj }));
        navigateToProfile(updatedProfileObj);
    };

    const navigateToProfile = (profileData) => {
        navigate(
            getRoute({
                ...user,
                activeAccountProfile: profileData,
            })
        );
    };

    useEffect(() => {
        if (initialValue) {
            setSelectedOption(initialValue);
        }
    }, [initialValue]);

    if (noActiveProfiles) {
        return <Navigate to='/no-active-profile' replace />;
    }

    return (
        <SelectProfileWrapper>
            <Container>
                <Row marginBottom={'5px'}>
                    <PageTitle varient='h2'>Select Profile</PageTitle>
                </Row>
                <Row marginBottom={'30px'}>
                    <HelperText varient='pl3'>{description}</HelperText>
                </Row>
                <Row marginBottom={'25px'}>
                    <ProfileList
                        profileList={accountProfiles}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                </Row>
                <Row>
                    <Button
                        onClick={() => handleContinue()}
                        fullWidth
                        style={styles.button}
                        disabled={isLoadingAccountProfileDetails || isUpdating}
                        loading={isLoadingAccountProfileDetails || isUpdating}
                    >
                        Continue
                    </Button>
                </Row>
            </Container>
        </SelectProfileWrapper>
    );
}

export default SelectProfile;
