import { useState } from 'react';
import {
  Box,
  Stack,
  MenuItem,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetAllPersonAttributesQuery } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import {
  resetInProgressProviders,
  updateConnectingProvider,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';

import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';

import { NavigatorOption } from './NavigatorOption';
import { ReactComponent as Activities } from 'images/navigator/activities.svg';
import { ReactComponent as Hospital } from 'images/navigator/hospital.svg';
import { ReactComponent as Newspaper } from 'images/navigator/newspaper.svg';
import { ReactComponent as Home } from 'images/navigator/home.svg';
import { ReactComponent as Expand } from 'images/navigator/expand.svg';
import { ReactComponent as Ellipse } from 'images/navigator/ellipse.svg';
import { ReactComponent as Collapse } from 'images/navigator/collapse.svg';
import { ReactComponent as NotesDiary } from 'images/navigator/notes-diary.svg';
import { ReactComponent as Award } from 'images/navigator/award.svg';
import { ReactComponent as Avatar } from 'images/navigator/avatar.svg';
import {
  Container,
  StyledNavigatorGroup,
  NavBar,
  getStyles,
  StyledMenu,
} from './styles';
import { useLogout } from 'utils/useLogout';
import { findStudyAttribute } from 'utils/study';
import { isMobile } from 'utils/isMobile';
import { DEFAULT_MAIN_NAVIGATION } from './constants';
import { getFormFields } from 'pages/Profile/MyProfile/utils';
import { CancelModal } from 'pages/SyncData/SearchProvidersV2/cancelModal';
import { persistor } from 'App';
import { getPersonFullNameAndInitials } from 'pages/User/utils';
import { useIsActiveProfileContactRole } from 'features/profile/hooks/useIsActiveProfileContactRole';

export const Navigator = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { person } = useSelector((state) => state.user);
  const styles = getStyles(theme, props.isExpanded);
  // Fix expanded menu small screens
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isActiveProfileContactRole } = useIsActiveProfileContactRole();

  const logout = useLogout();

  const [anchorElProfileOption, setAnchorElProfileOption] = useState(null);
  const openProfileOption = Boolean(anchorElProfileOption);

  // const { person_id } = useGetCurrentUserQuery(undefined, {
  //   selectFromResult: ({ data }) => ({
  //     person_id: data?.data?.included?.[0]?.[0]?.data?.id,
  //   }),
  // });

  // const { personAttributes } = useGetAllPersonAttributesQuery(person_id, {
  //   skip: !person_id,
  //   selectFromResult: ({ data }) => ({
  //     personAttributes: data?.included,
  //   }),
  // });

  const { personAttributes } = useSelector((state) => state.user);

  const { accountProfiles } = useGetAccountProfileDetails({
    personId: person?.id,
  });

  const firstName = personAttributes?.first_name;
  const lastName = personAttributes?.last_name;
  const preferredName = personAttributes?.preferred_name;

  const { fullName, initials } = getPersonFullNameAndInitials(firstName, lastName, preferredName);

  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { studyConfiguration } = useSelector((state) => state.configuration);
  const { finishDataShareEnabled, inProgressProviders } = oneUpHealthData;
  const shouldShowPopUp =
    finishDataShareEnabled || inProgressProviders?.length > 0;
  const shouldShowSwitchProfile = accountProfiles?.length > 1;

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const onCloseCancelModal = () => setIsOpenCancelModal(false);

  const navigate = useNavigate();
  const navigateBack = () => {
    dispatch(resetInProgressProviders());
    dispatch(updateConnectingProvider(null));
    return navigate('/my-activities');
  };

  const activePage = {
    home: matchPath({ path: '/home' }, location.pathname),
    myActivity:
      matchPath({ path: '/my-activities' }, location.pathname) ||
      matchPath({ path: '/activity/sync-data' }, location.pathname) ||
      matchPath({ path: '/activity/provider' }, location.pathname) ||
      matchPath({ path: '/activity/sync-data-final' }, location.pathname) ||
      matchPath({ path: '/activity/article/:id' }, location.pathname) ||
      matchPath({ path: '/activity/survey/:id' }, location.pathname) ||
      matchPath({ path: '/activity/video/:id' }, location.pathname) ||
      matchPath({ path: '/activity/schedule-call/:id' }, location.pathname) ||
      matchPath({ path: '/activity/make-call/:id' }, location.pathname),
    research:
      matchPath({ path: '/research-opportunities' }, location.pathname) ||
      matchPath({ path: '/research-opportunities/:id' }, location.pathname),
    education:
      matchPath({ path: '/education-materials' }, location.pathname) ||
      matchPath({ path: '/education-materials/:id' }, location.pathname),
    tools:
      matchPath({ path: '/tools/(.*)' }, location.pathname) ||
      matchPath({ path: '/tools' }, location.pathname),
    progress:
      matchPath({ path: '/progress/(.*)' }, location.pathname) ||
      matchPath({ path: '/progress' }, location.pathname),
    profile:
      matchPath({ path: '/profile/*' }, location.pathname) ||
      matchPath({ path: '/profile/my-profile' }, location.pathname) ||
      matchPath({ path: '/profile/my-profile/(.*)' }, location.pathname) ||
      matchPath({ path: '/profile/(.*)' }, location.pathname) ||
      matchPath({ path: '/profile' }, location.pathname),
    payment:
      matchPath({ path: '/payment/(.*)' }, location.pathname) ||
      matchPath({ path: '/payment' }, location.pathname),
    notifications:
      matchPath({ path: '/notifications/(.*)' }, location.pathname) ||
      matchPath({ path: '/notifications' }, location.pathname),
  };

  const mainNavigation =
    findStudyAttribute(studyConfiguration || [], 'main_navigation') ||
    DEFAULT_MAIN_NAVIGATION;

  const getTitle = (config, key) => {
    const found = config.filter((n) => n.key === key);
    if (found.length === 1) {
      return found[0]?.label['en-us'];
    }
    return null;
  };

  const isNavigationOptionEnabled = (config, targetKey) => {
    const found = config.find((c) => c.key === targetKey && c.display);
    return found !== undefined;
  };

  const onMenuClose = () => {
    setAnchorElProfileOption(null);
  };
  const onSwitchProfileClick = () => {
    onMenuClose();
    navigate('/switch-profile');
  };
  const onLogoutClick = () => {
    onMenuClose();
    logout();
  };

  const profileRoute = isActiveProfileContactRole ? '/profile/accountholder-profile':'/profile/participant-profile';

  return (
    <Container {...props}>
      {CancelModal({ isOpenCancelModal, onCloseCancelModal, navigateBack })}
      <StyledNavigatorGroup
        defaultValue='medical'
        shouldNavigate={() => {
          if (shouldShowPopUp) {
            setIsOpenCancelModal(true);
            return false;
          }
          return true;
        }}
      >
        <NavBar>
          {isNavigationOptionEnabled(mainNavigation, 'home') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'home')}
              checked={activePage.home}
              value='/home'
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Home
                  color={`${activePage.home
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'my_activity') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'my_activity')}
              checked={activePage.myActivity}
              value='/my-activities'
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Activities
                  color={`${activePage.myActivity
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'research') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'research')}
              checked={activePage.research}
              value='/research-opportunities'
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Hospital
                  color={`${activePage.research
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'education') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'education')}
              checked={activePage.education}
              value='/education-materials'
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Newspaper
                  color={`${activePage.education
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'study_overview') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'progress')}
              checked={activePage.progress}
              value='/progress'
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <NotesDiary
                  color={`${activePage.tools
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'my_rewards') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'my_rewards')}
              value='/payment'
              checked={activePage.payment}
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Award
                  color={`${activePage.payment
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
          {isNavigationOptionEnabled(mainNavigation, 'my_profile') && (
            <NavigatorOption
              title={getTitle(mainNavigation, 'my_profile')}
              value={isMobile() ? '/profile' : profileRoute}
              checked={activePage.profile}
              isExpanded={props.isExpanded && !isSmallScreen}
              icon={
                <Avatar
                  color={`${activePage.profile
                    ? theme.palette.primary.darkGray100
                    : 'white'
                    }`}
                />
              }
            />
          )}
        </NavBar>
      </StyledNavigatorGroup>

      {!props.isExpanded && (
        <Box sx={styles.expand}>
          <Expand onClick={() => props?.toggleMenu()} />
        </Box>
      )}
      {props.isExpanded && (
        <Box sx={styles.collapse}>
          <Collapse onClick={() => props?.toggleMenu()} />
        </Box>
      )}

      {
        <Box sx={styles.bottomContainer}>
          <Box sx={styles.fullNameContainer}>
            <Box>
              <Ellipse />
            </Box>
            <Box sx={styles.initials}>{initials}</Box>
            {props.isExpanded && (
              <Stack sx={styles.nameProfileContainer}>
                <Box sx={styles.fullName}>
                  {fullName}
                </Box>

                <Box
                  id='profile-options-button'
                  aria-controls={
                    openProfileOption ? 'profile-options-menu' : undefined
                  }
                  aria-expanded={openProfileOption ? 'true' : undefined}
                  onClick={(event) =>
                    setAnchorElProfileOption(event.currentTarget)
                  }
                  variant='contained'
                  sx={styles.switchProfile}
                >
                  Profile Options
                </Box>

                <StyledMenu
                  id='profile-options-menu'
                  anchorEl={anchorElProfileOption}
                  MenuListProps={{
                    'aria-labelledby': 'profile-options-button',
                  }}
                  open={openProfileOption}
                  onClose={onMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  {shouldShowSwitchProfile && (
                    <>
                      <MenuItem onClick={onSwitchProfileClick}>
                        <Typography variant='body2' color='text.secondary'>
                          Switch profile
                        </Typography>
                      </MenuItem>
                      <Divider />
                    </>
                  )}
                  <>
                    <MenuItem onClick={onLogoutClick}>
                      <Typography
                        variant='body2'
                        color={theme.palette.primary.darkblue70}
                        sx={{ fontWeight: '600' }}
                      >
                        Log out
                      </Typography>
                    </MenuItem>
                  </>
                </StyledMenu>
              </Stack>
            )}
          </Box>
        </Box>
      }
    </Container>
  );
};
