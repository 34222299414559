import { useGetAgeStatsMutation } from 'ihp-bloom-redux/features/registryStatistics/registryStatisticsApi';
import { useEffect, useState } from 'react';
import { theme } from '../../../theme';
import ReactECharts from 'echarts-for-react';
import { Loader } from 'components/Loader';
import { ChartContainer } from './styles';

const RegistryParticipantAgeChart = () => {
  const [getChartData, result] = useGetAgeStatsMutation();


  const [chartOptions, setChartOptions] = useState({});

  const sortByAgeLimitAsc = (dataArray) => {
    function compareByAgeLimit(a, b) {
      const ageLimitA = parseInt(a?.node?.ageLimit?.split('-')?.[0] ?? 0);
      const ageLimitB = parseInt(b?.node?.ageLimit?.split('-')?.[0] ?? 0);
      return ageLimitA - ageLimitB;
    }

    return dataArray?.sort(compareByAgeLimit);
  };

  const getYaxisData = (node, total) => {
    const value = node.count_participant;
    let threshold = Math.floor((total * 2) / 100);
    // Start changes for - SING-1135
    // threshold = threshold < 15 ? 15 : threshold;
       threshold = 15;
    // End changes for - SING-1135

    return {
      value,
      label: {
        formatter: value < threshold ? `<${threshold}` : value,
        position: value < threshold && total > 100 ? 'top' : 'insideTop',
      },
    };
  };

  const getChartOptions = (xAxis, yAxis, total) => {
    return {
      tooltip: {
        show: true,
        formatter: (params) => {
          const value = isNaN(params.value) ? '0' : params.value.toString();
          return `${params.name}: ${value}`;
        },
      },
      grid: {
        top: '20%',
        containLabel: true,
      },
      title: {
        // SING-509
        // text: 'Age of STUDY participants\n',
        text: 'Age of Study Participants at Enrollment\n',
        subtext: [`Total = ${total}`],
        textStyle: {
          color: theme.palette.primary.gray75,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: 500,
        },
        subtextStyle: {
          color: theme.palette.primary.gray75,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        axisLabel: {
          interval: 0,
          fontFamily: 'Inter',
          formatter: function (value, index) {
            if (window.screen.width >= 600 || index == 0) {
              return value;
            }
            const data = value?.split('-');
            if (data.length < 2) {
              return value;
            }
            return `${data?.[0]}-\n${data?.[1]}`;
          },
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: yAxis,
          type: 'bar',
          color: theme.palette.primary.darkblue100,
          label: {
            show: true,
            position: 'insideTop',
          },
        },
      ],
    };
  };

  useEffect(() => {
    getChartData();
  }, []);

  useEffect(() => {
    const edges = result?.data?.data?.vw_dtp_registry_participants_age?.edges;
    if (!edges) {
      return;
    }
    const clonedEdges = [...edges];
    const xAxis = [];
    const yAxis = [];
    const total = clonedEdges.reduce(
      (acc, item) => acc + item?.node?.count_participant,
      0
    );

    let nullBar = null;
    const nullFilteredIndex = clonedEdges.findIndex(
      (item) => !item?.node?.age_limit
    );
    if (nullFilteredIndex > -1) {
      nullBar = clonedEdges.splice(nullFilteredIndex, 1);
    }
    const sortedEdges = sortByAgeLimitAsc(clonedEdges);
    sortedEdges?.forEach((item) => {
      xAxis.push(item.node.age_limit);
      yAxis.push(getYaxisData(item.node, total));
    });
    if (nullBar) {
      xAxis.push('Others');
      yAxis.push(getYaxisData(nullBar?.[0]?.node, total));
    }
    const chartOptionsData = getChartOptions(xAxis, yAxis, total);
    setChartOptions(chartOptionsData);
  }, [result]);

  if (result.isLoading) {
    return <Loader />;
  }

  return (
    <ChartContainer>
      <ReactECharts
        option={chartOptions}
        style={{ height: '100%', width: '100%' }}
      />
    </ChartContainer>
  );
};

export default RegistryParticipantAgeChart;
