export const getStudyDescription = (data) => {
  const { data: attrList = [] } = data;
  const targetAttr = attrList.find(
    (attr) => attr?.data.attributes.attribute === 'full_study_description'
  );

  if (!targetAttr) return [];
  const contentArray = targetAttr?.data?.attributes?.value;
  return contentArray;
};

export const getMyStudyCallCenterPhone = (data) => {
  const { data: attrList = [] } = data;

  const targetAttr = attrList.find(
    (attr) => attr?.data.attributes.attribute === 'call_centre_phone'
  );

  if (!targetAttr) return null;
  const { value = {} } = targetAttr?.data?.attributes;

  return value;
};

export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
