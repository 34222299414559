import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { persistor } from 'App.js';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { getAuth0LoginPageConfig } from 'utils/auth0';

function QuickEnrollment() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { loginWithRedirect } = useAuth0();

    const urlParams = new URLSearchParams(location.search);
    const reporterInvitedToken = urlParams.get('token');

    const clearStorageAndSetValues = useCallback(async () => {
        sessionStorage.clear();
        localStorage.clear();
        await persistor.purge();
        if (!reporterInvitedToken) {
            return navigate("/");
        }
        dispatch(setValues({
            language: 'en-US',
            prescreening_data: JSON.stringify({
                is_eligible: true,
            }),
            reporterInviteToken: reporterInvitedToken ?? null,
        }));

        // Manually flush the persistor to ensure state is persisted before redirecting
        await persistor.flush();

        return loginWithRedirect(
            getAuth0LoginPageConfig({
                context: 'signup',
                prescreening_data: JSON.stringify({
                    is_eligible: true,
                }),
            })
        );
    }, [dispatch, navigate, reporterInvitedToken, loginWithRedirect]);

    useEffect(() => {
        clearStorageAndSetValues();
    }, [clearStorageAndSetValues]);

    return null;
}

export default QuickEnrollment;
